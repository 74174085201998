
import { defineComponent, onMounted, ref, reactive, nextTick, h, computed } from 'vue'
import {
  getBrand,
  getUser,
  searchVehicle,
} from '@/API/checking/checkingManagement';

interface BrandType {
  id: number;
  brandNameCn: string;
  brandNameEn: string;
}

export default defineComponent({
  props: {
    type: {
      type: String
    }
  },
  setup(props, ctx) {
    const bu = ref<string | null>(null);
    const buList = ref<any[]>([]);

    const make = ref<string[]>([]);
    const makeList = ref<string[]>([]);

    const brand = ref<string[]>([]);
    const brandList = ref<BrandType[]>([]);

    const typeClass = ref<string[]>([]);
    const typeClassList = ref<string[]>([]);

    const powerTrain = ref<string[]>([]);
    const powerTrainList = ref<string[]>([]);

    const isEmptyBrand = ref<boolean>(false);

    const getVehicleItems = () => {
      if (brand.value?.length === 0) {
        return    
      }
      const params = {
        brand: brand.value,
        make: make.value,
        // modelName: modelName.value,
        modelName: [],
        nst: [],
        typeClass: typeClass.value,
        powerTrain: powerTrain.value,
      };
      searchVehicle(params).then((res: any) => {
        makeList.value = res.make;
        powerTrainList.value = res.powerTrain;
        typeClassList.value = res.typeClass;
      });
    };

    const handleBrandChange = () => {
      if (brand.value?.length === 0) {
        make.value = []
        typeClass.value = []
        powerTrain.value = [] 
      }
    }

    const selectBrand = () => {
      isEmptyBrand.value = brand.value === null ? true : false;
      make.value = [];
      // modelName.value = []
      powerTrain.value = [];
      typeClass.value = [];
      makeList.value = []
      typeClassList.value = []
      powerTrainList.value = []
      getVehicleItems();
    };

    const handleUpdate = () => {
      const buFormat = buList.value.filter(item => {
        return item.id === bu.value
      }).map(item => {
        return item.nameEn
      })[0]
      const data: any = {
        bu: buFormat,
        buId: bu.value,
        makeList: make.value,
        brandList: brand.value,
        typeClassList: typeClass.value,
        powerTrainList: powerTrain.value
      }
      if (data.brandList === null) {
        delete data.brandList
      }
      ctx.emit('update', data)
    }

    const findBrandByBu = () => {
      const params = { buIds: [bu.value] } //TODO 获取buList
      getBrand({ params }).then((res) => {
        const selectedIdList: number[] = []
        const totalBrandList: {
          id: number;
          brandNameCn: string;
          brandNameEn: string;
        }[] = []
        res.forEach((bu: any) => {
          bu.brandList.forEach((brand: any) => {
            if (!selectedIdList.includes(brand.id)) {
              selectedIdList.push(brand.id)
              totalBrandList.push(brand)
            }
          })
        })
        brandList.value = totalBrandList
        brand.value = []
        make.value = []
        powerTrain.value = []
        typeClass.value = []
        makeList.value = []
        typeClassList.value = []
        powerTrainList.value = []
        handleUpdate()
      })
    }

    //? 数据初始化阶段
    onMounted(() => {
      getUser()
        .then((res: any) => {
          buList.value = res.buList.filter((ele: any) => {
              return ele.parentId === null && ele.nameEn !== 'CPO' && ele.nameEn !== 'Fleet' && ele.nameEn !== 'Companycar';
          });
          bu.value = buList.value[0].id;
          // bu.value = '1'
        })
        .then(() => {
          return findBrandByBu()
        })
    })

    return {
      bu,
      buList,
      make,
      makeList,
      brand,
      brandList,
      typeClass,
      typeClassList,
      powerTrain,
      powerTrainList,
      isEmptyBrand,
      findBrandByBu,
      handleBrandChange,
      selectBrand,
      handleUpdate,
      getVehicleItems
    }
  }
})
