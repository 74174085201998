import GC from "@grapecity/spread-sheets";
//分组列在第一次没有数据初始化后会导致后续更新报错，所以分组列必须要在有数据才可以分组
export function initOutlineColumn(sheet: GC.Spread.Sheets.Worksheet) {
  sheet.outlineColumn.options({
      columnIndex: 0,
      showImage: true,
      showCheckBox: false,
      expandIndicator: require('@/assets/images/increaseIndicator.png'),
      collapseIndicator: require('@/assets/images/decreaseIndicator.png'),
      maxLevel: 2
  });
  sheet.showRowOutline(false);
  sheet.outlineColumn.refresh();
}

export const setCollapsed = (sheet: GC.Spread.Sheets.Worksheet, data: any [], isCollapsed: boolean) => {
  sheet.suspendPaint();
  //强制折叠所有二级
  for (let row = 0; row < data.length; row++) {
      if (data[row].level === 1) {
          sheet.rowOutlines.setCollapsed(row, isCollapsed);
      }
  }
  sheet.outlineColumn.refresh();
  sheet.resumePaint();
}

// 设置表格的头部，可以不占用单元格
export const setHeader = (sheet: GC.Spread.Sheets.Worksheet, header: any[][]) => {
  //挂起
  sheet.suspendPaint();
  //设置header行数为2行
  sheet.setRowCount(2, GC.Spread.Sheets.SheetArea.colHeader);
  const row = sheet.getRange(1, -1, 1, -1, GC.Spread.Sheets.SheetArea.colHeader);
  row.wordWrap(true);
  //set header 设置头的样式
  for (let row = 0; row < header.length; row++) {
    for (let cell = 0; cell < header[row].length; cell++) {
      sheet.setValue(row, cell, header[row][cell].name, GC.Spread.Sheets.SheetArea.colHeader);
      sheet.getCell(row, cell, GC.Spread.Sheets.SheetArea.colHeader).hAlign(GC.Spread.Sheets.HorizontalAlign.left);
      if (cell > 2) {
        sheet.getCell(row, cell, GC.Spread.Sheets.SheetArea.colHeader).hAlign(GC.Spread.Sheets.HorizontalAlign.right);
      }
      // if ([1].includes(cell)) {
      //   sheet.getCell(row, cell, GC.Spread.Sheets.SheetArea.colHeader).hAlign(GC.Spread.Sheets.HorizontalAlign.left);
      // }
    }
    sheet.setRowHeight(row, 30, GC.Spread.Sheets.SheetArea.colHeader);
  }
  //合并行 
  // sheet.addSpan(0, 0, 2, 1, GC.Spread.Sheets.SheetArea.colHeader);
  // 筛选
  // const range = new GC.Spread.Sheets.Range(-1, 0, -1, 2);
  // const rowFilter = new GC.Spread.Sheets.Filter.HideRowFilter(range);
  // sheet.rowFilter(rowFilter);
  // sheet.addSpan(0, 0, 1, 2, GC.Spread.Sheets.SheetArea.colHeader);
  // sheet.addSpan(0, 2, 1, 4, GC.Spread.Sheets.SheetArea.colHeader);
  // sheet.addSpan(0, 6, 1, 4, GC.Spread.Sheets.SheetArea.colHeader);
  // // 必须加上这两个才能折叠展开
  sheet.options.protectionOptions.allowOutlineRows = true
  sheet.options.protectionOptions.allowOutlineColumns = true
  // 自动合并
  // const ranges = new GC.Spread.Sheets.Range(-1, -1, -1, -1);
  // sheet.autoMerge(ranges, GC.Spread.Sheets.AutoMerge.AutoMergeDirection.row, GC.Spread.Sheets.AutoMerge.AutoMergeMode.free, GC.Spread.Sheets.SheetArea.colHeader);
  // sheet.autoMerge(ranges, GC.Spread.Sheets.AutoMerge.AutoMergeDirection.column, GC.Spread.Sheets.AutoMerge.AutoMergeMode.free, GC.Spread.Sheets.SheetArea.rowHeader);2
  //重绘
  sheet.resumePaint();

}

export const bindDataTable = (sheet: GC.Spread.Sheets.Worksheet, data: any[], forcastIndex: number): void => {
  //挂起
  sheet.suspendPaint();
  //设置数据源
  sheet.setDataSource(data);

  /* //setDataSource true 重置表格样式也会重置,这里重新设置默认样式
  sheet.options.rowHeaderVisible = false;
  //默认对其方式
  const defaultStyle = new GC.Spread.Sheets.Style();
  //默认对其方式
  defaultStyle.hAlign = GC.Spread.Sheets.HorizontalAlign.center;
  defaultStyle.vAlign = GC.Spread.Sheets.VerticalAlign.center;
  defaultStyle.locked = true;
  sheet.setDefaultStyle(defaultStyle);
*/


  //绑定列
  sheet.bindColumn(0, 'brand');
  sheet.bindColumn(1, 'typeClass');
  sheet.bindColumn(2, 'nstGroupName');
  sheet.bindColumn(3, 'january');
  sheet.bindColumn(4, 'february');
  sheet.bindColumn(5, 'march');
  sheet.bindColumn(6, 'april');
  sheet.bindColumn(7, 'may');
  sheet.bindColumn(8, 'june');
  sheet.bindColumn(9, 'july');
  sheet.bindColumn(10, 'august');
  sheet.bindColumn(11, 'september');
  sheet.bindColumn(12, 'october');
  sheet.bindColumn(13, 'november');
  sheet.bindColumn(14, 'december');

  //设置列数
  sheet.setColumnCount(15, GC.Spread.Sheets.SheetArea.viewport);

  //设置没列的宽度
  sheet.setColumnWidth(0, '2*');
  sheet.setColumnWidth(1, '1*');
  sheet.setColumnWidth(2, '4*');
  sheet.setColumnWidth(3, '1*');
  sheet.setColumnWidth(4, '1*');
  sheet.setColumnWidth(5, '1*');
  sheet.setColumnWidth(6, '1*');
  sheet.setColumnWidth(7, '1*');
  sheet.setColumnWidth(8, '1*');
  sheet.setColumnWidth(9, '1*');
  sheet.setColumnWidth(10, '1*');
  sheet.setColumnWidth(11, '1*');
  sheet.setColumnWidth(12, '1*');
  sheet.setColumnWidth(13, '1*');
  sheet.setColumnWidth(14, '1*');

  //数据格式化
  for (let row = 0; row < data.length; row++) {
    const level = data[row].level;
    sheet.getCell(row, 0).textIndent(level);
    sheet.getCell(row, 1).wordWrap(true);
    sheet.getCell(row, 0).hAlign(GC.Spread.Sheets.HorizontalAlign.left);
    sheet.getCell(row, 1).hAlign(GC.Spread.Sheets.HorizontalAlign.left);
    sheet.getCell(row, 2).hAlign(GC.Spread.Sheets.HorizontalAlign.left);
    sheet.setRowHeight(row, 25, GC.Spread.Sheets.SheetArea.viewport);
    sheet.setFormatter(row, 3, "0.00%", GC.Spread.Sheets.SheetArea.viewport);
    sheet.setFormatter(row, 4, "0.00%", GC.Spread.Sheets.SheetArea.viewport);
    sheet.setFormatter(row, 5, "0.00%", GC.Spread.Sheets.SheetArea.viewport);
    sheet.setFormatter(row, 6, "0.00%", GC.Spread.Sheets.SheetArea.viewport);
    sheet.setFormatter(row, 7, "0.00%", GC.Spread.Sheets.SheetArea.viewport);
    sheet.setFormatter(row, 8, "0.00%", GC.Spread.Sheets.SheetArea.viewport);
    sheet.setFormatter(row, 9, "0.00%", GC.Spread.Sheets.SheetArea.viewport);
    sheet.setFormatter(row, 10, "0.00%", GC.Spread.Sheets.SheetArea.viewport);
    sheet.setFormatter(row, 11, "0.00%", GC.Spread.Sheets.SheetArea.viewport);
    sheet.setFormatter(row, 12, "0.00%", GC.Spread.Sheets.SheetArea.viewport);
    sheet.setFormatter(row, 13, "0.00%", GC.Spread.Sheets.SheetArea.viewport);
    sheet.setFormatter(row, 14, "0.00%", GC.Spread.Sheets.SheetArea.viewport);
    sheet.setFormatter(row, 15, "0.00%", GC.Spread.Sheets.SheetArea.viewport);
    
    // 加粗typeclass
    sheet.getCell(row, 0).font('700 11pt Calibri');  
  }
  //重绘
  const style = new GC.Spread.Sheets.Style();
  style.locked = false;
  style.backColor = 'lightGreen';
  style.borderLeft =new GC.Spread.Sheets.LineBorder("gray", GC.Spread.Sheets.LineStyle.thin);
  style.borderTop = new GC.Spread.Sheets.LineBorder("gray", GC.Spread.Sheets.LineStyle.thin);
  style.borderRight = new GC.Spread.Sheets.LineBorder("gray", GC.Spread.Sheets.LineStyle.thin);
  style.borderBottom = new GC.Spread.Sheets.LineBorder("gray", GC.Spread.Sheets.LineStyle.thin);
  for (let i = forcastIndex; i < 15; i++) {
    sheet.setStyle(-1, i + 3, style);
  }
  sheet.resumePaint();
}

export const initSpread = (spread: GC.Spread.Sheets.Workbook, sheet: GC.Spread.Sheets.Worksheet, header: any[][], data: any[], forcastIndex: number): void => {
  spread.suspendPaint();

  //填充铺满整个canvas
  spread.options.scrollbarMaxAlign = true;
  spread.options.scrollByPixel = true;

  spread.options.tabNavigationVisible = false;
  spread.options.tabStripVisible = false;

  // 滚动条样式
  spread.options.scrollbarAppearance = GC.Spread.Sheets.ScrollbarAppearance.mobile;

  // 设置header的高度
  // sheet.setRowHeight(0, 80, GC.Spread.Sheets.SheetArea.colHeader);

  // 设置多少行和列
  sheet.setRowCount(data.length, GC.Spread.Sheets.SheetArea.viewport);

  //禁止缩放
  spread.options.allowUserZoom = false;
  // 必须加上这两个才能折叠展开
  sheet.options.protectionOptions.allowOutlineRows = true
  sheet.options.protectionOptions.allowOutlineColumns = true

  // 设置整个表格不能插入， 删除行列
  // allowInsertRows不允许插入行，allowInsertColumns不允许插入列
  // allowDeleteRows不允许删除行, allowDeleteColumns不允许删除列
  sheet.options.protectionOptions.allowInsertRows = false;
  sheet.options.protectionOptions.allowInsertColumns = false;
  sheet.options.protectionOptions.allowDeleteRows = false;
  sheet.options.protectionOptions.allowDeleteColumns = false;
  sheet.options.protectionOptions.allowEditObjects = true;
  

  //禁止拖动填充
  spread.options.allowUserDragFill = false;
  spread.options.allowUserDragDrop = false;

  spread.options.allowCopyPasteExcelStyle = false

  // 设置隐藏头和列
  sheet.options.rowHeaderVisible = false
  // 要设置允保护，不允许的才能生效
  sheet.options.isProtected = true

  const defaultStyle = new GC.Spread.Sheets.Style();
  //默认对其方式
  defaultStyle.hAlign = GC.Spread.Sheets.HorizontalAlign.right;
  defaultStyle.vAlign = GC.Spread.Sheets.VerticalAlign.center;
  defaultStyle.locked = true;
  sheet.setDefaultStyle(defaultStyle);
  // 绑定数据
  bindDataTable(sheet, data, forcastIndex);

  // 设置头部
  setHeader(sheet, header);

  spread.resumePaint();
}

export const formatDataForSpread = (list: any, level = 0, result: any[] = [], field = 'controllingPoint', parentId = null) => {
  for (let i = 0; i < list.length; i++) {
    const obj = {
      level: level,
      id: list[i].id,
      brand: list[i].brand || '',
      nstGroupName: list[i].nstGroupName || '',
      typeClass: list[i].typeClass || '',
      january: list[i].january?.[field] === null ? null : list[i].january[field] / 100,
      february: list[i].february?.[field] === null ? null : list[i].february[field] / 100,
      march: list[i].march?.[field] === null ? null : list[i].march[field] / 100,
      april: list[i].april?.[field] === null ? null : list[i].april[field] / 100,
      may: list[i].may?.[field] === null ? null : list[i].may[field] / 100,
      june: list[i].june?.[field] === null ? null : list[i].june[field] / 100,
      july: list[i].july?.[field] === null ? null : list[i].july[field] / 100,
      august: list[i].august?.[field] === null ? null : list[i].august[field] / 100,
      september: list[i].september?.[field] === null ? null : list[i].september[field] / 100,
      october: list[i].october?.[field] === null ? null : list[i].october[field] / 100,
      november: list[i].november?.[field] === null ? null : list[i].november[field] / 100,
      december: list[i].december?.[field] === null ? null : list[i].december[field] / 100,
      childIds: list[i].children?.map((child: any) => child.id),
      parentId: parentId
    }
    result.push(obj)
    if (list[i].children && list[i].children.length > 0) {
      formatDataForSpread(list[i].children, 1, result, field, list[i].id)
    }
  }
  return result
}

export const formatDataForTable = (oldTableData: any[], spreadJsData: any[], field = 'controllingPoint') => {
  const months = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']
  oldTableData.forEach(tableRow => {
    spreadJsData.forEach(excelRow => {
      // 同一行
      if (excelRow.id === tableRow.id) {
        months.forEach((month: string) => {
          if (excelRow[month] || excelRow[month] === 0) {
            if (tableRow[month][field] !== excelRow[month] * 100) {
              tableRow[month].edited = true
            }
            tableRow[month][field] = excelRow[month] * 100
          } else { // null的情况
            if (tableRow[month][field] || tableRow[month][field] === 0) {
              tableRow[month][field] = excelRow[month]
              tableRow[month].edited = true
            }
          }
        }) 
      }
    })
    if (tableRow.children && tableRow.children.length > 0) {
      formatDataForTable(tableRow.children, spreadJsData, field)
    }
  })
}