
import { useRoute } from 'vue-router'
import { defineComponent, onMounted, ref, reactive, nextTick, h, computed, watch } from 'vue'
import { initSpread, formatDataForSpread, initOutlineColumn, setCollapsed } from './spreadUtils'
import { message ,Modal} from "ant-design-vue";
import { useStore } from 'vuex'
import GC from "@grapecity/spread-sheets"
import { IO } from "@grapecity/spread-excelio"
import * as ExcelIO from '@grapecity/spread-excelio'
import saveAs from 'file-saver'
export default defineComponent({
  props: {
    spreadVisible: {
      type: Boolean
    },
    spreadData: {
      type: Array
    },
    actuals: {
      type: Array
    },
    field: {
      type: String
    },
    year: {
      type: String
    },
    makeName: {
      type: String
    }
  },
  setup(props, ctx) {
    GC.Spread.Sheets.LicenseKey = (ExcelIO as any).LicenseKey = 'oasis.mercedes-benz.com.cn|oasis-training.mercedes-benz.com.cn|oasis-uat.mercedes-benz.com.cn|oasis-dev.mercedes-benz.com.cn,858593977834555#B0j3vnNr3SYhRHVj5kbqtkWXRDMW3SNqFFON9GaMJ7YaV7QjVkZENjR7kXZ6RHeBpEN58WT6QUY42WeJ36ZEt4azlnZ9kXR624VtZzcrAlRTJ4LudWSMlDVul5KG3CeNNXOEZVVwInV6I6LUVTO486cwRldIdDepBHNYJ6cDJWbT36L0RXT6BFT7cTWHBHeQNncygXT59UTmh7bLdmNRtiY6RmTHRlciNnZ9ljQGtmSvUXS5J4KyUHMYRUNRRnU5lkYjN5ThJnMYZ5ayN4dMlFVtR6TmJXY5UUUrsyc9M4N9ZjNMplcxllMtRTTxxkeSZTbw3mMn5WW054LRdFelNFZvcHelNkTHFWUTV7VyUjR7hjMOJVaJdXO5plZyI6T5ZDcIVnc6NnZ55kbwFzV9cERiojITJCLiYUMGFTQDZUNiojIIJCLxIDN8ETMyETN0IicfJye&Qf35VfiUURJZlI0IyQiwiI4EjL6BCITpEIkFWZyB7UiojIOJyebpjIkJHUiwiIzATNwkDMgYDM4ATMyAjMiojI4J7QiwiIuNmLt36YuonblJWLzVGZlNmcl5mL6VGZtMXazF6bs86Yu46bj9ieuVmYtMXZkV6YyVWbuQXY55ycpNXYvxibj9SbvNmL09WZi5yclRWZjJXZt9yZulmbpFmc45ycpNXYvxibj9SbvNmL09WZi5yclRWZjJXZt9ycpNXYvJiOiMXbEJCLig1jlzahlDZmpnInmTYtoXpimrLjl1Yjl7KukfKplL9ilb8plTLimLiOiEmTDJCLiUTN5QzM8czN9MTO5gTN8IiOiQWSiwSflNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPRR5QoFHTzgDdslEZiB5dDJGarFkR5QmNNFFcvBnYLRkWXBlfzCX';

    const store = useStore()

    const excelIo = new ExcelIO.IO();

    const spinning = ref<boolean>(false)

    const route: any = useRoute()

    const expandModel = ref<boolean>(false)

    const dialogVisible = ref<boolean>(false)
    // sheet当前表格的初始化
    let workbook: GC.Spread.Sheets.Workbook | null;

    let worksheet:  GC.Spread.Sheets.Worksheet | null;

    let data: any

    const handleOk = () => {
      const data = worksheet?.getDataSource();
      ctx.emit('update', data)
      ctx.emit('close')
      expandModel.value = false
      workbook?.destroy()
    }

    const handleCancle = () => {
      ctx.emit('close')
      expandModel.value = false
      workbook?.destroy()
    }

    const initData = (data: any) => {
      const header: any[][] = [
        [
          {
            name: props.makeName,
          },
          {
            name: route.query.scenarioName,
          },
          {
            name: '',
          },
          ...(props.actuals?.map(item => {
            return {
              name: item ? 'Actual' : 'Forecast'
            }
          }) ?? [])
        ],
        [
          {
            name: 'Brand',
          },
          {
            name: 'Type Class',
          },
          {
            name: 'Model',
          },
          ...(props.actuals?.map((item, index) => {
            return {
              name: `${props.year}-${index < 9 ? '0' : ''}${index + 1}`
            }
          }) ?? [])
        ]
      ]
      const spread = new GC.Spread.Sheets.Workbook(document.getElementById('report') as HTMLElement);
      
      const sheet = spread.getActiveSheet();
      sheet.bind(GC.Spread.Sheets.Events.EditEnded, function (s: any, e: any) {
        const month = ['', '', '', 'january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']
        const data = worksheet?.getDataSource();
        const rowIndex = e.row
        const colIndex = e.col
        
        const rowInfo = data[rowIndex]
        const value = rowInfo[month[colIndex]]
        const childIds = rowInfo.childIds
        
        if (childIds && childIds.length > 0) {
          data.forEach((row: any) => {
            if (childIds?.includes(row.id)) {
              row[month[colIndex]] = value
            } 
          })
          worksheet?.setDataSource(data);
        }
        if (rowInfo.parentId) {
          data.forEach((row: any) => {
            if (row.id === rowInfo.parentId) {
              row[month[colIndex]] = null
            } 
          })
          worksheet?.setDataSource(data);
        }
      })

      sheet.bind(GC.Spread.Sheets.Events.ClipboardPasted, function (sender: any, args: any) {
        const month = ['', '', '', 'january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']
        const data = worksheet?.getDataSource()
        const rowIndex = args.cellRange.row
        const colIndex = args.cellRange.col
        const rowCount = args.cellRange.rowCount
        const colCount = args.cellRange.colCount
        const rowInfo = data.filter((item: any, index: any)=> index >= rowIndex && index < rowIndex + rowCount)
        for (let i = colIndex;i < colIndex + colCount; i++) {
          rowInfo.forEach((row: any) => {
            if (row.childIds && row.childIds.length > 0) {
              const value = row[month[i]]
              data.forEach((dataRow: any) => {
                if (row.childIds.includes(dataRow.id) && value) {
                  dataRow[month[i]] = value
                }
              })
            }
          })
        }
      });
      worksheet = sheet;
      workbook = spread;
      const forcastIndex: any = props.actuals?.indexOf(false)
      spread.options.pasteSkipInvisibleRange = true
      initSpread(spread, sheet, header, data, forcastIndex)
      initOutlineColumn(sheet)
      setCollapsed(sheet, data, true)
    }

    const handleClear = () => {
      Modal.confirm({
        title: 'Tips',
        content: 'Are you sure to delete all forcast data?',
        okText: 'Confirm',
        async onOk() {
          store.commit('updateSpinning', true)
          spinning.value = true
          let monthList = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']
          let forcastIndex = 0
          for (let i = 0;i < 12;i ++) {
            if (!props.actuals?.[i]) {
              forcastIndex = i
              break
            }
          }
          // props.actuals?.forEach((item: any, index: any) => {
          //   console.log(item)
          //   if (!item) {
          //     forcastIndex = index
          //   }
          // })
          monthList = monthList.slice(forcastIndex, 12)
          const data = worksheet?.getDataSource()
          data.forEach((row: any) => {
            monthList.forEach(month => {
              row[month] = null
            })
          })
          worksheet?.setDataSource(data)
          // 重绘
          worksheet?.resumePaint()
          store.commit('updateSpinning', false)
        },
        cancelText: 'Cancel'
      })
    }

    watch([() => props.spreadVisible, () => props.spreadData], ([spreadVisibleValue, spreadDataValue]) => {
      dialogVisible.value = Boolean(spreadVisibleValue)
      if (spreadVisibleValue) {
        nextTick(()=>{
          if (props.field === 'buPoint') {
            data = formatDataForSpread(spreadDataValue, 0, [], 'buPoint')
          } else {
            data = formatDataForSpread(spreadDataValue)
          }
          initData(data)
        })
      }
    }, {
      immediate: true
    })

    watch(expandModel, (newValue) => {
      setCollapsed(worksheet as any, data, !newValue)
    })

    const handleExport = () => {
      const options = {
        includeBindingSource: true, 
        columnHeadersAsFrozenRows:true,
        rowHeadersAsFrozenColumns: false
      }
      const json = workbook?.toJSON(options)
      excelIo.save(json as any, function (blob: any) {
        saveAs.saveAs(blob, 'Guideline.xlsx')
      }, function (e: any) {
        console.log(e)
      })
    }

    return {
      dialogVisible,
      expandModel,
      spinning,
      handleOk,
      handleCancle,
      handleClear,
      handleExport
    }
  }
})
